@import 'src/assets/styles/variables';

$button-margin: $spacer-2;
$quantity-input-min-width: calc($input-height + 0.7rem);
$quantity-input-min-width-sm: calc($input-height-sm + 0.5rem);
$quantity-input-min-width-lg: calc($input-height-lg + 1rem);

.wrapper {
  padding: 0;
  display: flex;
  flex-wrap: nowrap !important;
  width: fit-content !important;

  &.fullWidth {
    width: 100% !important;
  }

  &.sm {
    .input {
      width: $quantity-input-min-width-sm !important;
    }
    .button {
      width: $input-height-sm;
    }
  }

  &.lg {
    .input {
      width: $quantity-input-min-width-lg !important;
    }
    .button {
      width: $input-height-lg;
    }
  }
}

.button {
  background-color: $gray-200;
  transition: background-color 0.2s ease-in-out;
  width: $input-height;
  border: $input-btn-border-width solid $border-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: $gray-100;
    .icon {
      color: $gray-300;
    }
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    outline: none;
    background-color: $light-primary;

    .icon {
      color: $primary;
    }
  }

  &:first-child {
    border-radius: $border-radius 0 0 $border-radius;
    overflow: hidden;
  }

  &:last-child {
    border-radius: 0 $border-radius $border-radius 0;
    overflow: hidden;
  }
}

.input {
  border: $input-btn-border-width solid $border-color;
  text-align: center;
  width: $quantity-input-min-width !important;
}

.icon {
  color: $gray-600;
  transition: color 0.2s ease-in-out;
}
