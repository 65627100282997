@import 'src/assets/styles/variables';

.priceContainer {
  display: inline-block;
  [dir='rtl'] & {
    direction: ltr;
    text-align: right;
  }
}

.currency {
  font-family: $font-family-currency;
  display: inline-block;
  transform: scale(1.05) translateY(0.8px);
}
