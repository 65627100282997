@import '~bootstrap/scss/functions';

/*
  -------------------
  Clinicy Variables
  -------------------
*/

$clinicy-color-1: #14bfd6;
$clinicy-color-2: #00d1cd;
$clinicy-secondary: #95b0b5;
$clinicy-gray: darken($clinicy-secondary, 30%);
$clinicy-menu-width: 80px;
$clinicy-header-height: 60px;
$clinicy-box-padding: 20px;
$clinicy-btn-box-shadow-primary-sm: 0 5px 15px -2px rgba(lighten($clinicy-color-1, 7%), 0.7);
$clinicy-btn-box-shadow-primary: 0 5px 20px -2px rgba(lighten($clinicy-color-1, 7%), 0.7);
$clinicy-toolbar-box-shadow: 0 0.125rem 2.5rem -0.35rem rgba(darken($clinicy-gray, 10%), 0.25);
$clinicy-gradient-1: linear-gradient(45deg, $clinicy-color-1, $clinicy-color-2);

// Ease functions
$easeClinicy: cubic-bezier(0.25, 0.8, 0.25, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);

/*
  -------------------
  Bootstrap Variables
  -------------------
*/

// Color system
$white: #fff;
$gray-100: lighten($clinicy-gray, 63%);
$gray-200: lighten($clinicy-gray, 61%);
$gray-300: lighten($clinicy-gray, 52%);
$gray-400: lighten($clinicy-gray, 30%);
$gray-500: lighten($clinicy-gray, 20%);
$gray-600: lighten($clinicy-gray, 10%);
$gray-700: $clinicy-gray;
$gray-800: darken($clinicy-gray, 10%);
$gray-900: darken($clinicy-gray, 20%);
$black: #000;

$blue: #24bcff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e85050;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #2ace73;
$teal: #20c997;
$cyan: #00cfe8;

$primary: $clinicy-color-1;
$secondary: $clinicy-secondary;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$light-primary: tint-color($primary, 90%);
$light-secondary: tint-color($secondary, 86%);
$light-success: tint-color($success, 88%);
$light-info: tint-color($info, 90%);
$light-warning: tint-color($warning, 90%);
$light-danger: tint-color($danger, 90%);

// Status colors
$status-booked: $primary;
$status-confirmed: #a582dc;
$status-waiting: #ffda3d;
$status-checked-in: $success;
$status-no-show: #ff7b33;
$status-conflicting: #e53d31;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

$custom-colors: (
  'light-primary': $light-primary,
  'light-secondary': $light-secondary,
  'light-success': $light-success,
  'light-info': $light-info,
  'light-warning': $light-warning,
  'light-danger': $light-danger,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg: $gray-100;
$body-color: $gray-700;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 15%);
$link-hover-decoration: null;

// Options
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: false;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Spacing
$spacer: 1.125rem;

$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
);

$spacer-0: map-get($spacers, 0);
$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);
$spacer-6: map-get($spacers, 6);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$grid-breakpoints-max: (
  sm: 575px,
  md: 767px,
  lg: 991px,
  xl: 1199px,
  xxl: 1399px,
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: $spacer-4;
$grid-row-columns: 6;

$gutters: $spacers;

// Container padding
$container-padding-x: $grid-gutter-width * 0.5;

// Components
$border-width: 1px;

$border-color: $gray-300;

$border-radius: 6px;
$border-radius-sm: 4px;
$border-radius-lg: 12px;
$border-radius-pill: 50rem;

// Box shadows
$box-shadow-color: $gray-800;
$box-shadow-opacity: 0.15;
$box-shadow: 0 0 2.5rem -0.75rem rgba($box-shadow-color, $box-shadow-opacity);
$box-shadow-sm: 0 0 1rem -0.25rem rgba($box-shadow-color, $box-shadow-opacity);
$box-shadow-lg: 0 0.75rem 2.5rem -0.75rem rgba($box-shadow-color, $box-shadow-opacity);
$box-shadow-inset: inset 0 1px 2px rgba($box-shadow-color, $box-shadow-opacity);

$component-active-color: $white;
$component-active-bg: $primary;

// Typography
$font-family-sans-serif: 'Tajawal', sans-serif;
$font-family-currency: 'Sar', sans-serif;

$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.9;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.4;
$line-height-sm: 1.2;
$line-height-lg: 1.8;

$h1-font-size: $font-size-base * 2.8;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-base * 0.9,
);
// scss-docs-end font-sizes

$headings-margin-bottom: $spacer;
$headings-font-weight: $font-weight-medium;
$headings-line-height: 1.2;

$text-muted: $gray-600;

$hr-margin-y: $spacer;
$hr-color: $gray-200;
$hr-height: $border-width;
$hr-opacity: 1;

$dt-font-weight: $font-weight-medium;

// Tables
$table-cell-padding-y: $clinicy-box-padding;
$table-cell-padding-x: $clinicy-box-padding;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;

$table-cell-vertical-align: middle;

$table-color: $body-color;
$table-bg: transparent;
$table-accent-bg: transparent;

$table-th-font-weight: $font-weight-medium;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.5;
$table-striped-bg: rgba($light-secondary, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: 0.5;
$table-active-bg: rgba($light-secondary, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: 0.5;
$table-hover-bg: rgba($light-secondary, $table-hover-bg-factor);

$table-border-factor: 1;
$table-border-width: $border-width;
$table-border-color: $border-color;

$table-striped-order: odd;

// Buttons + Forms
$input-btn-padding-y: 0.563rem;
$input-btn-padding-x: 1.25rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.12;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity);
$input-btn-focus-blur: 1rem;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.375rem;
$input-btn-padding-x-sm: 0.875rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: $border-width;

// Buttons
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: nowrap;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $font-size-lg;

$btn-border-width: 1px;

$btn-font-weight: $font-weight-medium;
$btn-box-shadow: none;
$btn-focus-width: 0rem;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.6;
$btn-active-box-shadow: none;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius;

// Forms
$form-label-margin-bottom: 0.375rem;
$form-label-font-size: $font-size-sm;
$form-label-font-style: null;
$form-label-font-weight: $font-weight-medium;
$form-label-color: null;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: 0.75rem;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: 0.5rem;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: 1rem;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $white;
$input-disabled-bg: $gray-100;
$input-disabled-border-color: null;

$input-color: $body-color;
$input-border-color: $border-color;
$input-border-width: $input-btn-border-width;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius;

$input-focus-border-color: $primary;

$input-placeholder-color: $gray-400;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5);

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;

$form-color-width: 3rem;

// scss-docs-start form-check-variables
$form-check-input-width: 1.375em;

$form-check-input-active-filter: brightness(108%);

$form-check-input-bg: $input-bg;
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-border-radius: $border-radius-sm;
$form-check-radio-border-radius: 50%;

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: 1rem;

// scss-docs-start form-switch-variables
$form-switch-width: 2.5em;
$form-switch-color: $gray-400;

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-100;
$input-group-addon-border-color: $input-border-color;

// Dropdowns
$dropdown-min-width: 8rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-sm;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $gray-300;
$dropdown-border-radius: $border-radius-sm;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: $spacer * 0.5;
$dropdown-box-shadow: $box-shadow-sm;

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $dropdown-link-color;
$dropdown-link-hover-bg: $gray-200;

$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: $gray-200;

$dropdown-link-disabled-color: $gray-500;

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: $gray-600;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

// Pagination
$pagination-padding-y: 0.25rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-start: -$pagination-border-width;
$pagination-border-color: $gray-300;

$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

// Z-index master list
$zindex-date-picker: 20;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-header: 1022;
$zindex-menu: 1024;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
$zindex-notification: 1090;

// Navs
$nav-link-padding-y: add($btn-padding-y, 0.065rem);
$nav-link-padding-x: $btn-padding-x;
$nav-link-color: $body-color;
$nav-link-hover-color: $body-color;
$nav-link-disabled-color: $gray-400;

$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $primary;

// Accordion

// scss-docs-start accordion-variables
$accordion-bg: $white;
$accordion-icon-width: 1.125rem;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $gray-900;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: $spacer * 0.25;
$tooltip-padding-x: $spacer * 0.5;
$tooltip-margin: 0.25rem 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
// scss-docs-end tooltip-feedback-variables

// Badges
$badge-font-size: 0.875rem;
$badge-font-weight: $font-weight-medium;
$badge-color: $white;
$badge-padding-y: 0.3rem;
$badge-padding-x: 0.6rem;
$badge-border-radius: $border-radius;

// Modals
$modal-inner-padding: $spacer-4;

$modal-footer-margin-between: $spacer-2;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 2.5rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $border-color;
$modal-content-border-width: 0px;
$modal-content-border-radius: $border-radius-lg;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: tranparent;
$modal-backdrop-opacity: 1;
$modal-header-border-color: $gray-200;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: 1px;
$modal-footer-border-width: 0;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;
$modal-xxl: 1320px;

$modal-fade-transform: scale(0.95) translate(0, -60px);
$modal-show-transform: none;
$modal-transition: all 0.7s $easeOutExpo;
$modal-scale-transform: scale(1); // 1.02

// Image thumbnails
$thumbnail-padding: 0rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: 0;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: $box-shadow-sm;

// Spinners
$spinner-width: 1.5rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: middle;
$spinner-border-width: 0.188em;
$spinner-animation-speed: 0.6s;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.125em;

// Close
$btn-close-width: 0.75em;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $gray-700;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
$btn-close-focus-shadow: none;
$btn-close-opacity: 0.5;
$btn-close-hover-opacity: 0.75;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 0.25;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);

// Signature
$signature-displayer-height: 150px;

// RequirementsTooltip
$requirements-tooltip-fixed-max-width: 250px;

// Documents
$a4-document-preview-width: 826px; // 2480/3  (A4/3, 300dpi)
